import GATSBY_COMPILED_MDX from "/opt/build/repo/blog/yet-another-post.mdx";
import React from 'react';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
var BlogPost = function BlogPost(_ref) {
  var data = _ref.data, children = _ref.children;
  return React.createElement(Layout, {
    pageTitle: data.mdx.frontmatter.title
  }, React.createElement("p", null, data.mdx.frontmatter.date), children);
};
var query = "1083539204";
export var Head = function Head(_ref2) {
  var data = _ref2.data;
  return React.createElement(Seo, {
    title: data.mdx.frontmatter.title
  });
};
BlogPost
export default function GatsbyMDXWrapper(props) {
  return React.createElement(BlogPost, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
